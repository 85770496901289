import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "utils/helper";

const initialState = {
  annualLeavePeriodicTypes: [],
  annualLeaveStartingPeriodTypes: [],
  approvalStageTypes: [],
  approvalStatuses: [],
  approvalTypes: [],
  approvalUserTypes: [],
  attendanceStatuses: [],
  bloodTypes: [],
  citizenships: [],
  educationLevels: [],
  employeeStatuses: [],
  employeeTerminatedReasons: [],
  eventCalendarTypes: [],
  genders: [],
  idCardTypes: [],
  leaveTypes: [],
  maritalStatuses: [],
  reassignmentTypes: [],
  prorateRuleUserTypes: [],
  religions: [],
  latenessRules: [],
  hrPlafonTypes: [],
  a1Types: [],
  annualLeaveCashedTypes: [],
  bpjsDeathProtectionTypes: [],
  bpjsHealthcareCompanyResponsibilities: [],
  bpjsHealthcareEmployeeResponsibilities: [],
  bpjsHealthcareMultiplierTypes: [],
  bpjsOldAgeProtectionCompanyResponsibilities: [],
  bpjsOldAgeProtectionEmployeeResponsibilities: [],
  bpjsPensionProtectionCompanyResponsibilities: [],
  bpjsPensionProtectionEmployeeResponsibilities: [],
  bpjsWorkingAccidentProtectionMultiplierTypes: [],
  bpjsWorkingAccidentProtectionTypes: [],
  componentTypes: [],
  leaveAllowanceMultiplierTypes: [],
  overtimePayTypes: [],
  fingerprintAttendanceLogStatus: [],
  hrOvertimeTypes: [],
  hrOvertimeTimeTypes: [],
  pph21CalculationMethods: [],
  proratePromotionTypes: [],
  prorateResignTypes: [],
  prorateTypes: [],
  salaryComponentTypes: [],
  salaryPeriods: [],
  salaryPeriodTypes: [],
  hrRoundingPaymentSlipPeriodTypes: [],
  taxpayerStatuses: [],
  thrMultiplierTypes: [],
  thrUnderOneYearTypes: [],
  unpaidLeaveDeductionNonFixedTypes: [],
  unpaidLeaveDeductionTypes: [],
  workingLocations: [],
  hrFinanceCoaRuleUserTypes: [],
  employeeReminderTerminationType: [],
  reminderTimeTypes: [],
  taskPriorities: [],
  taskApprovalStatuses: [],
  taskStatuses: [],
  paymentConnectProviders: [],
  hrSalaryComponentFixedTypes: [],
};

const slice = createSlice({
  name: "references",
  initialState,
  reducers: {
    setReference: (state, { payload }) =>
      updateObject(state, {
        ...state,
        ...payload,
      }),
  },
});

export const { setReference } = slice.actions;

export default slice;
